import React from 'react';
import { Row, Col, Card } from 'antd';
import './EdgeTechnology.css';
import Rectangle1 from './Svg_files/erp cut image 1.svg';
import Rectangle2 from './Svg_files/erp cut image 2.svg';
import Rectangle3 from './Svg_files/erp cut image 3.svg';
import edgeTechnologyImage from '../../../assets/images/EdgeTechnology/Group 23.svg';

function EdgeTechnology() {

    const cards = [
        {
            title: 'OCR Capability',
            description: 'Transform documents into data',
            image: Rectangle1,

        },
        {
            title: 'Automation Tools',
            description: 'Let the software handle repetitive tasks',
            image: Rectangle2,
        },
        {
            title: 'Real-Time Analytics',
            description: 'Make informed decisions faster',
            image: Rectangle3,
        },
    ];

    return (
        <div  className="my-background">
          
            <h2 className='HeadingAlign'>Compreo.ai brings cutting-edge technology to your business:</h2>
            <div className="three-card-view">
                <Row gutter={[16, 16]} className='CardAlignHoverable'>
                    {cards.map((card, index) => (
                        <Col xs={24} sm={12} md={7} key={index}>
                            <Card
                                hoverable
                                cover={<img alt={card.title} src={card.image} />}
                                className="custom-card"
                            >
                                <Card.Meta style={{color: '#fff !important'}} className='CardData' title={card.title} description={card.description} />
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>);
}

export default EdgeTechnology;