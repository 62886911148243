// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import Module_image from '../GlobalModules/Image_hs';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Simplify Complexity with Scalable ",
    headingSpan: "Project Systems. ",
    subtext: "Manage complex projects with integrated tools for planning, scheduling, execution, and resource management, ensuring timely and successful completion. "
};

const features = [
    { question: 'Integrated Project Planning', answer: 'Combine planning, scheduling, and resource allocation in a unified platform to streamline project management. This feature facilitates the coordination of all project elements, aligning schedules, resources, and tasks, ensuring that projects are efficiently planned and executed from start to finish. ' },
    { question: 'Milestone and Task Tracking', answer: 'Monitor project progress against defined milestones and tasks with detailed tracking tools. This feature helps keep projects on track by providing visibility into task completion, milestone achievements, and overall project status, ensuring timely progress and early identification of potential delays. ' },
    { question: 'Resource Utilization Management', answer: 'Optimize the use of human and material resources by tracking and managing their allocation. This feature ensures efficient resource deployment, minimizes wastage, and maximizes productivity by providing insights into resource availability, utilization, and allocation throughout the project lifecycle.' },
    { question: 'Cost Estimation and Control', answer: 'Estimate project costs accurately and monitor expenses against the budget. This feature supports financial management by providing tools for cost forecasting, budget tracking, and expense control, helping ensure that projects remain within budget and financial resources are effectively managed. ' },
    { question: 'Collaboration Tools', answer: 'Facilitate team collaboration with integrated communication and file-sharing tools. This feature enhances team interaction, supports real-time collaboration, and streamlines information sharing, improving coordination and efficiency in project execution through seamless communication channels and document management.' },
    { question: 'Risk and Issue Management', answer: 'Identify, assess, and manage project risks and issues effectively. This feature provides tools for risk assessment, issue tracking, and mitigation planning, helping to proactively address potential problems and minimize their impact on project success. ' },
    { question: 'Time Tracking and Billing', answer: 'Track time spent on tasks for accurate client billing and project accounting. This feature supports precise billing by recording time entries, managing invoices, and ensuring that project work is accurately accounted for, facilitating transparent and timely financial transactions. ' },
    { question: 'Real-Time Reporting', answer: 'Generate real-time reports on project progress, resource usage, and costs. This feature provides up-to-date insights into project status, helping stakeholders make informed decisions and track project performance against objectives and budgets. ' },
    { question: 'Document Management', answer: 'Centralize storage for all project-related documents and files. This feature ensures organized document handling, easy access to critical information, and effective version control, supporting smooth project operations and documentation management throughout the project lifecycle. ' },
];

const ProjectSystems = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                <Row className="ERP_alignmentModules" gutter={16}>
                        <Col
                            xs={{ span: 24, order: 2 }} // Span 24 and second on extra small screens
                            sm={{ span: 24, order: 2 }} // Span 24 and second on small screens
                            md={{ span: 24, order: 2 }} // Span 13 and first on medium screens
                            lg={{ span: 13, order: 1 }} // Span 13 and first on large screens
                            xl={{ span: 13, order: 1 }} // Span 13 and first on extra large screens
                        >
                            <Module_image />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }} // Span 24 and first on extra small screens
                            sm={{ span: 24, order: 1 }} // Span 24 and first on small screens
                            md={{ span: 24, order: 1 }} // Span 11 and second on medium screens
                            lg={{ span: 11, order: 2 }} // Span 11 and second on large screens
                            xl={{ span: 11, order: 2 }} // Span 11 and second on extra large screens
                        >
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}

        </React.Fragment>
    );
};

export default (ProjectSystems);
