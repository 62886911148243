import React from "react";
import './Manufacturing.scss';
import { Row, Col } from 'antd';
import Source_img from './Svg_files/manufacturing ind hs image.svg'
import Logo_img1 from './Svg_files/raw-materials.svg';
import Logo_img2 from './Svg_files/inventory.svg';
import Logo_img3 from './Svg_files/quality.svg';
import Logo_img4 from './Svg_files/supply-chain.svg';

import Production from './Svg_files/production.svg'
import Operation from "./Svg_files/operation.svg";
import Quality from './Svg_files/quality-service.svg'
import resource from './Svg_files/resource-allocation.svg'

import Analytics from './Svg_files/manufacturing ind ben image.svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "Seamless Raw Material and Production Tracking", subtext: "Real-time tracking of raw materials and production processes to minimize delays, reduce waste, and enhance overall manufacturing efficiency.", logo: Logo_img1 },
        { id: 2, heading: "Inventory Optimization and Control", subtext: "Efficiently manage inventory levels, minimize stockouts, reduce excess stock, and ensure materials are available when needed for production.", logo: Logo_img2 },
        { id: 3, heading: "Integrated Quality Management", subtext: "Automated quality checks throughout the production cycle to ensure product consistency, compliance, and customer satisfaction.", logo: Logo_img3 },
        { id: 4, heading: "Advanced Supply Chain Planning", subtext: "Leverage data-driven insights for demand forecasting, supply planning, and resource allocation to optimize the supply chain process.", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "BI-Based Insights on Metrics", subtext: "Gain real-time insights into key performance indicators for improved decision-making and strategic production planning." },
        { id: 2, heading: "Seamless Integration with Supply Chain Partners", subtext: "Enhance collaboration and communication with suppliers and distributors, reducing lead times and improving overall supply chain efficiency." },
        { id: 3, heading: "Automated Quality Checks", subtext: "Reduce defects and ensure consistent product quality by automating quality control checks throughout the production process." },
        { id: 4, heading: "Comprehensive Inventory Management", subtext: "Minimize wastage, optimize stock levels, and reduce holding costs with integrated inventory management tools." }
    ],
    benefitsCards: [
        { id: 1, heading: "Improved Production Efficiency", subtext: "Streamline production workflows to increase throughput and reduce operational bottlenecks.", image: Production },
        { id: 2, heading: "Reduced Operational Costs", subtext: "Streamline production workflows to increase throughput and reduce operational bottlenecks.", image: Operation },
        { id: 3, heading: "Enhanced Quality Assurance", subtext: "Ensure consistent product quality through automated quality checks and integrated quality management.", image: Quality },
        { id: 4, heading: "Better Demand Forecasting and Resource Allocation", subtext: "Optimize production and supply chain planning with accurate demand forecasts and resource allocation.", image: resource }
    ]
};

function Manufacturing() {
    const questions = [
        { question: 'How does Compreo help with inventory management? ', answer: 'Compreo offers real-time inventory visibility, automated replenishment, and data-driven insights to reduce excess stock and prevent stockouts.' },
        { question: 'Can Compreo integrate with existing manufacturing systems? ', answer: 'Yes, Compreo seamlessly integrates with existing ERP systems, machinery, and supply chain partners to provide a unified manufacturing platform.' },
        { question: 'How does Compreo enhance quality control? ', answer: 'Compreo automates quality checks, ensuring defects are minimized and consistent product quality is maintained across production cycles.' },
        { question: 'What BI tools does Compreo offer for manufacturing?', answer: 'Compreo provides comprehensive BI tools to analyze production metrics, forecast demand, and optimize resource planning.' },
        { question: ' How does Compreo support lean manufacturing? ', answer: 'Compreo optimizes inventory management, reduces waste, and streamlines workflows, aligning with lean manufacturing principles.' },
        { question: 'Can Compreo handle multi-location manufacturing operations?', answer: 'Yes, Compreo supports multi-site manufacturing operations with centralized data management and integrated process controls.' }
    ];

    return (
        <div className="industry-parent">
            <div className="industry-your-future">
                <div className="industry-your-future-container">
                    {/* Section for Heading, Subtext, and Image */}
                    <div className="industry-your-future-text-section">
                        
                        <div className="industry-your-future-image">
                            <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                            <div className="industry-your-future-text-container">
                        <div className="industry-your-future-text-heading">
                            Manufacturing Industry
                        </div>
                        <div className="industry-your-future-text-subtext">
                            "Transform Manufacturing with Intelligent ERP Solutions"
                        </div>
                        </div>
                        </div>
                    </div>

                    {/* Section for Description and Cards */}
                    <div className="industry-your-future-content-section">
                        <div className="industry-your-future-description">
                            Compreo ERP empowers manufacturing businesses with robust tools for managing production, inventory, supply chain, and quality control. It helps streamline processes, reduce waste, and improve production efficiency with real-time data and advanced automation.
                        </div>
                        <div className="industry-your-future-cards">
                            <Row gutter={[16, 16]} justify="center">
                                {data.industryFutureCards.map(card => (
                                    <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                        <div className="industry-your-future-cards-container">
                                            <div className="industry-your-future-cards-container-logo">
                                                <img src={card.logo} alt="Logo_alt" />
                                            </div>
                                            <div className="industry-your-future-cards-container-heading">
                                                {card.heading}
                                            </div>
                                            <div className="industry-your-future-cards-container-subtext">
                                                {card.subtext}
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span>Manufacturing Industry</span></div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container-left-heading">Benefits</div>

                <div className="industry-benefits-container">
                   
                                    <Row gutter={[16, 16]}>
                                        {data.benefitsCards.map((card) => (
                                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                                <div className="industry-benefits-container-left-cards-image">
                                                    <img className="industry-benefits-container-left-cards-image-logo" src={card.image} alt="alternative" />
                                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                               
                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
        </div>
    );
}

export default Manufacturing;
