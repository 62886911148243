import React from "react";
import { Row, Col } from 'antd';
import Source_img from './Svg_files/dis ind hs image.svg'
import Logo_img1 from './Svg_files/shipping.svg';
import Logo_img2 from './Svg_files/delivery-management.svg';
import Logo_img3 from './Svg_files/ready-stock.svg';
import Logo_img4 from './Svg_files/vendor.svg';

import Production from './Svg_files/fast-delivery.svg'
import Operation from "./Svg_files/utilization.svg";
import Quality from './Svg_files/satisfaction.svg'
import resource from './Svg_files/reduce-cost.svg'

import Analytics from './Svg_files/dis ind ben image.svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "Dynamic Inventory Visibility", subtext: "Provides real-time visibility into inventory levels across locations, enhancing inventory control and reducing stock discrepancies.", logo: Logo_img1 },
        { id: 2, heading: "Multi-Channel Order Management", subtext: "Streamlines order processing across multiple sales channels, improving order accuracy and customer satisfaction.", logo: Logo_img2 },
        { id: 3, heading: "Automated Stock Replenishment", subtext: "Automates the restocking process based on demand forecasts, preventing stockouts and reducing excess inventory.", logo: Logo_img3 },
        { id: 4, heading: "Vendor and Supplier Integration", subtext: "Integrates with suppliers for better order tracking, collaboration, and streamlined procurement processes.", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "Streamline Inventory Control", subtext: "Centralize inventory management across multiple warehouses, improving inventory accuracy and reducing storage costs." },
        { id: 2, heading: "Improve Customer Satisfaction", subtext: "Enhance order fulfillment accuracy and speed, leading to higher customer satisfaction and retention." },
        { id: 3, heading: "Automate Restocking Processes", subtext: "Reduce manual intervention in restocking, ensuring optimal inventory levels and avoiding stockouts or overstock situations." },
        { id: 4, heading: "Simplify Supplier Managment", subtext: "Centralize supplier information and streamline communication to enhance supplier relationships and operational efficiency." }
    ],
    benefitsCards: [
        { id: 1, heading: "Faster Order Processing and Delivery", subtext: "Streamlined order management processes lead to quicker order fulfillment and delivery times.", image: Production },
        { id: 2, heading: "Improved Inventory Turnover", subtext: "Efficient inventory management reduces carrying costs and improves inventory turnover ratios.", image: Operation },
        { id: 3, heading: "Enhanced Customer Experience", subtext: "Accurate order fulfillment and timely deliveries improve customer satisfaction and loyalty.", image: Quality },
        { id: 4, heading: "Reduced Supply Chain Costs", subtext: "Optimized supply chain operations lead to reduced transportation, storage, and procurement costs.", image: resource }
    ]
};

function Distribution() {
    const questions = [
        { question: 'How does Compreo manage multi-channel orders? ', answer: 'Compreo centralizes order management across sales channels, ensuring seamless processing, tracking, and fulfillment.' },
        { question: 'Can Compreo automate supplier collaboration?', answer: 'Yes, Compreo integrates with suppliers to automate communication, order tracking, and procurement processes.' },
        { question: 'How does Compreo help prevent stockouts?', answer: 'Compreo’s automated replenishment system uses demand forecasting to maintain optimal inventory levels and prevent stockouts.' },
        { question: 'Does Compreo support returns management?', answer: 'Yes, Compreo offers features to manage returns efficiently, ensuring accurate inventory updates and customer satisfaction.' },
        { question: 'Can Compreo handle bulk order processing?', answer: 'Yes, Compreo optimizes bulk order processing to ensure efficient handling and delivery.' },
        { question: 'How does Compreo improve supply chain visibility?', answer: 'Compreo provides real-time data and analytics on inventory, orders, and shipments to enhance supply chain visibility.' },
    ];
    return (
        <div className="industry-parent">
             <div className="industry-your-future">
                <div className="industry-your-future-container">
                    {/* Section for Heading, Subtext, and Image */}
                    <div className="industry-your-future-text-section">
                        
                        <div className="industry-your-future-image">
                            <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                            <div className="industry-your-future-text-container">
                        <div className="industry-your-future-text-heading">
                        DISTRIBUTION & SUPLLY CHAIN                        </div>
                        <div className="industry-your-future-text-subtext">
                        "Optimize Your Distribution Networks with Smart ERP"                        </div>
                        </div>
                        </div>
                    </div>

                    {/* Section for Description and Cards */}
                    <div className="industry-your-future-content-section">
                        <div className="industry-your-future-description">
                        Compreo ERP simplifies complex distribution operations by offering tracking of inventory, efficient order management, and seamless integration with multiple channels, ensuring timely and accurate deliveries.                        </div>
                        <div className="industry-your-future-cards">
                            <Row gutter={[16, 16]} justify="center">
                                {data.industryFutureCards.map(card => (
                                    <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                        <div className="industry-your-future-cards-container">
                                            <div className="industry-your-future-cards-container-logo">
                                                <img src={card.logo} alt="Logo_alt" />
                                            </div>
                                            <div className="industry-your-future-cards-container-heading">
                                                {card.heading}
                                            </div>
                                            <div className="industry-your-future-cards-container-subtext">
                                                {card.subtext}
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span>Distribution & Supply Chain</span></div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container-left-heading">Benefits</div>

                <div className="industry-benefits-container">
                   
                                    <Row gutter={[16, 16]}>
                                        {data.benefitsCards.map((card) => (
                                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                                <div className="industry-benefits-container-left-cards-image">
                                                    <img className="industry-benefits-container-left-cards-image-logo" src={card.image} alt="alternative" />
                                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                               
                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
        </div>
    );
}

export default Distribution;
