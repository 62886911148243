// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import ArrowIcon from './Svg_files/Arrow 28.svg';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import { Collapse } from 'antd';
import Module_image from '../GlobalModules/Image_hs';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Enhance Customer Experience with our ",
    headingSpan: "Dealer Portal",
    subtext: "An intuitive self-service platform for customers to access support, track orders, and manage their accounts, enhancing customer satisfaction and loyalty.  "
};

const features = [
    { question: 'Order History and Tracking', answer: 'Allow dealers to view their order history and track current orders in real-time. This feature provides transparency into order status, improves management, and enhances dealer satisfaction. ' },
    { question: 'Personalized Dashboard', answer: 'Offer a customized dashboard displaying account details, relevant promotions, and updates tailored to the dealer’s needs. This feature enhances the user experience by providing a personalized view and easy access to key information. ' },
    { question: 'Knowledge Base Access', answer: 'Provide access to a knowledge base with FAQs, guides, and tutorials. This feature supports self-service, allowing dealers to find answers to common questions and resolve issues independently.  ' },
    { question: 'Feedback and Reviews', answer: 'Enable dealers to submit feedback and reviews on products and services. This feature supports continuous improvement, provides valuable insights, and helps enhance dealer relationships.  ' },
    { question: 'Multi-Channel Support ', answer: 'Integrate multiple support channels such as chat, email, and phone to assist dealers. This feature ensures comprehensive support, effective issue resolution, and improved dealer satisfaction. ' },
    { question: 'Secure Account Management', answer: 'Implement robust security measures to protect dealer account data, including encryption and multi-factor authentication. This feature ensures safe access, enhances security, and builds trust with dealers. ' },
    { question: 'Order Modification and Cancellation', answer: 'Allow dealers to modify or cancel orders according to predefined terms and conditions. This feature provides flexibility, accommodates changes, and improves dealer satisfaction. ' },
];


const CustomerPortal = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);


    const toggleFeature = (feature) => {
        setSelectedFeature(feature);
    };


    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                <Row className="ERP_alignmentModules" gutter={16}>
                        <Col
                            xs={{ span: 24, order: 2 }} // Span 24 and second on extra small screens
                            sm={{ span: 24, order: 2 }} // Span 24 and second on small screens
                            md={{ span: 24, order: 2 }} // Span 13 and first on medium screens
                            lg={{ span: 13, order: 1 }} // Span 13 and first on large screens
                            xl={{ span: 13, order: 1 }} // Span 13 and first on extra large screens
                        >
                            <Module_image />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }} // Span 24 and first on extra small screens
                            sm={{ span: 24, order: 1 }} // Span 24 and first on small screens
                            md={{ span: 24, order: 1 }} // Span 11 and second on medium screens
                            lg={{ span: 11, order: 2 }} // Span 11 and second on large screens
                            xl={{ span: 11, order: 2 }} // Span 11 and second on extra large screens
                        >
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}

        </React.Fragment>
    );
};

export default (CustomerPortal);
