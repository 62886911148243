// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import Module_image from '../GlobalModules/Image_hs';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Drive Financial Growth with Intelligent ",
    headingSpan: "Financial Management",
    subtext: "Our Financial Accounting System integrated with business intelligence provides a comprehensive solution to manage all financial operations, automate processes, and generate actionable insights to support better business decisions. Stay in control of your finances with a feature-rich, cloud-based platform designed for accuracy and efficiency.  "
};

const features = [
    { question: 'General Ledger Management', answer: "Maintain accurate and up-to-date general ledger entries for comprehensive financial tracking. This feature ensures that all financial transactions are recorded correctly, providing a clear and accurate view of the company's financial position. " },
    { question: 'Accounts Payable and Receivable', answer: 'Streamline management of invoices, payments, and collections for efficient financial operations. This feature supports the handling of accounts payable and receivable by automating invoice processing, payment management, and collection tracking. ' },
    { question: 'Financial Reporting and Analysis', answer: 'Generate financial statements and custom reports for actionable insights. This feature provides tools for creating detailed financial reports, analyzing performance, and supporting strategic decision-making with accurate financial data. ' },
    { question: 'Budgeting and Forecasting', answer: 'Plan budgets and forecast future financial performance to support strategic planning. This feature helps in creating and managing budgets, projecting financial outcomes, and analyzing variances to ensure financial goals are met. ' },
    { question: 'Bank Reconciliation', answer: 'Automate reconciliation of bank transactions with financial records for accuracy. This feature streamlines the reconciliation process by matching bank statements with internal records, identifying discrepancies, and ensuring accurate financial reporting.  ' },
    { question: 'Tax Management and Compliance', answer: 'Ensure compliance with tax regulations and automate tax filings to reduce administrative burden. This feature supports tax management by automating calculations, filings, and compliance monitoring, ensuring adherence to tax laws and regulations. ' },
    { question: 'Cash Flow Management', answer: 'Monitor cash flow and optimize liquidity with real-time data to maintain financial stability. This feature provides tools for tracking cash inflows and outflows, managing liquidity, and forecasting cash needs to ensure smooth financial operations.' },
    { question: 'Customizable Balance Sheet', answer: 'Generate detailed balance sheets with drill-down capabilities for in-depth financial analysis. This feature allows for customizable balance sheet reports, providing detailed insights into assets, liabilities, and equity for comprehensive financial analysis.' },
    { question: 'Group Ledger', answer: 'Monitor customer orders, sales, and delivery status through organized ledger systems. This feature supports the management of group transactions by providing a consolidated view of customer-related activities, improving oversight and financial control.' },
];

const FinancialAccounting = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                <Row className="ERP_alignmentModules" gutter={16}>
                        <Col
                            xs={{ span: 24, order: 2 }} // Span 24 and second on extra small screens
                            sm={{ span: 24, order: 2 }} // Span 24 and second on small screens
                            md={{ span: 24, order: 2 }} // Span 13 and first on medium screens
                            lg={{ span: 13, order: 1 }} // Span 13 and first on large screens
                            xl={{ span: 13, order: 1 }} // Span 13 and first on extra large screens
                        >
                            <Module_image />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }} // Span 24 and first on extra small screens
                            sm={{ span: 24, order: 1 }} // Span 24 and first on small screens
                            md={{ span: 24, order: 1 }} // Span 11 and second on medium screens
                            lg={{ span: 11, order: 2 }} // Span 11 and second on large screens
                            xl={{ span: 11, order: 2 }} // Span 11 and second on extra large screens
                        >
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}

        </React.Fragment>
    );
};

export default (FinancialAccounting);
