import React from "react";
import { Row, Col } from 'antd';
import Source_img from './Svg_files/media ind hs image.svg'
import Logo_img1 from './Svg_files/distribution.svg';
import Logo_img2 from './Svg_files/management (1).svg';
import Logo_img3 from './Svg_files/financial-report.svg';
import Logo_img4 from './Svg_files/contracts.svg';

import Production from './Svg_files/copy-writing.svg'
import Operation from "./Svg_files/financial-report.svg";
import Quality from './Svg_files/contracts 1.svg'
import resource from './Svg_files/productivity 1.svg'

import Analytics from './Svg_files/media ind ben image.svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "Content Management and Distribution", subtext: "Centralizes content creation, management, and distribution, ensuring efficient workflows and content delivery across multiple platforms.", logo: Logo_img1 },
        { id: 2, heading: "Rights and Royalty Management", subtext: "Automates rights management and royalty calculations, ensuring accurate payments and compliance with contracts.", logo: Logo_img2 },
        { id: 3, heading: "Financial Planning and Analysis", subtext: "Provides tools for budgeting, financial forecasting, and analysis to support better financial management and decision-making.", logo: Logo_img3 },
        { id: 4, heading: "Financial Planning and Analysis", subtext: "Centralizes contract management and talent scheduling to streamline operations and enhance collaboration.", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "Streamline Content Creation and Distribution", subtext: "Centralized tools improve collaboration, reduce production time, and ensure timely distribution across channels." },
        { id: 2, heading: "Manage Rights and Royalties Efficiently", subtext: "Automated rights and royalty management simplifies complex calculations and ensures compliance." },
        { id: 3, heading: "Optimize Financial Planning and Budget Control", subtext: "Real-time financial insights aid in strategic planning and effective budget control." },
        { id: 4, heading: "Centralize Contract and Talent Management", subtext: "Simplifies the management of contracts, talent, and schedules, enhancing operational efficiency." }
    ],
    benefitsCards: [
        { id: 1, heading: "Increased Content Monetization", subtext: "Streamlined content management and distribution lead to better monetization opportunities.", image: Production },
        { id: 2, heading: "Improved Financial Transparency", subtext: "Real-time financial reporting provides clear visibility into revenue and expenses.", image: Operation },
        { id: 3, heading: "Better Talent and Contract Management", subtext: "Centralized contract management enhances talent relations and reduces administrative burdens.", image: Quality },
        { id: 4, heading: "Enhanced Operational Efficiency", subtext: "Automated processes streamline operations, reducing errors and increasing productivity.", image: resource }
    ]
};

function MediaEntertainment() {
    const questions = [
        { question: 'Can Compreo handle multi-channel content distribution?', answer: 'Yes, Compreo supports multi-channel content distribution, ensuring seamless delivery across various platforms.' },
        { question: 'How does Compreo manage complex royalty calculations? ', answer: 'Compreo automates royalty calculations based on predefined contracts, ensuring accurate and timely payments.' },
        { question: 'Does Compreo support collaboration among teams?', answer: 'Yes, Compreo facilitates collaboration among content creation, distribution, and financial teams for efficient workflows.' },
        { question: 'Can Compreo integrate with existing content management systems? ', answer: 'Yes, Compreo integrates with existing systems, providing a unified platform for media operations.' },
        { question: 'How does Compreo assist with financial planning? ', answer: 'Compreo provides tools for budgeting, forecasting, and financial analysis, supporting strategic planning.' },
        { question: 'Does Compreo offer talent management features? ', answer: 'Yes, Compreo centralizes talent management, including scheduling, contract management, and payments.' }
    ];

    return (
        <div className="industry-parent">
            <div className="industry-your-future">
                <div className="industry-your-future-container">
                    {/* Section for Heading, Subtext, and Image */}
                    <div className="industry-your-future-text-section">

                        <div className="industry-your-future-image">
                            <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                            <div className="industry-your-future-text-container">
                                <div className="industry-your-future-text-heading">
                                    MEDIA & ENTERTAINMENT
                                </div>
                                <div className="industry-your-future-text-subtext">
                                    "Drive Media & Entertainment Success with Compreo ERP"
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Section for Description and Cards */}
                    <div className="industry-your-future-content-section">
                        <div className="industry-your-future-description">
                            Compreo ERP enables media and entertainment companies to manage content creation, distribution, rights, and royalties with a robust platform, ensuring smooth operations and maximizing revenue.
                        </div>
                        <div className="industry-your-future-cards">
                            <Row gutter={[16, 16]} justify="center">
                                {data.industryFutureCards.map(card => (
                                    <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                        <div className="industry-your-future-cards-container">
                                            <div className="industry-your-future-cards-container-logo">
                                                <img src={card.logo} alt="Logo_alt" />
                                            </div>
                                            <div className="industry-your-future-cards-container-heading">
                                                {card.heading}
                                            </div>
                                            <div className="industry-your-future-cards-container-subtext">
                                                {card.subtext}
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span>Media & Entertainment</span></div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container-left-heading">Benefits</div>

                <div className="industry-benefits-container">

                    <Row gutter={[16, 16]}>
                        {data.benefitsCards.map((card) => (
                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                <div className="industry-benefits-container-left-cards-image">
                                    <img className="industry-benefits-container-left-cards-image-logo" src={card.image} alt="alternative" />
                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                </div>
                            </Col>
                        ))}
                    </Row>

                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
        </div>
    );
}

export default MediaEntertainment;
