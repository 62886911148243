import React from "react";
import Logo from './Svg_files/united-states-of-america (1) 1.svg';
import IND_Logo from './Svg_files/indan_location.svg'
import Arrow from './Svg_files/Arrow 11.svg'
import Branches_img from './Svg_files/Branches_background.svg'
import './branch.css'; // Ensure you have this CSS file


const Branches = () => {
    return (
        <div className="Branches" >
            <div className="Branches-heading" >Our Branch Locations</div>
            <div className="Branches-cards">
                <div className="location-card">
                    <div className="top-section">
                        <div className="location-info">
                            <div className="location-icon">
                                <img src={IND_Logo} alt="Location" />
                            </div>
                            <div className="location-details">
                                <div className="location-details-place">Hyderabad</div>
                                <div className="location-details-country">India</div>
                            </div>
                        </div>
                    </div>
                    <div className="middle-section">
                        <div><span>Address</span>: 5th Floor, Trendz Trident, Jubilee Enclave, HITEC City, Hyderabad, Telangana 500081</div>
                    </div>
                    <div className="bottom-section">
                        <div className="view-maps-button"><a target="_blank" href="https://maps.app.goo.gl/FGDw3bcUBG4K9aRt5">View on Map</a></div>
                        <div className="arrow-marks">
                            <img src={Arrow} alt="Arrow" />
                        </div>
                    </div>
                </div>

                <div className="location-card">
                    <div className="top-section">
                        <div className="location-info">
                            <div className="location-icon">
                                <img src={Logo} alt="Location" />
                            </div>
                            <div className="location-details">
                                <div className="location-details-place">Charlotte</div>
                                <div className="location-details-country">USA</div>
                            </div>
                        </div>
                    </div>
                    <div className="middle-section">
                        <div><span>Address</span>:  1300 South Blvd Suite D,
                            Charlotte, NC 28203</div>
                    </div>
                    <div className="bottom-section">
                        <div className="view-maps-button"><a target="_blank" href="https://maps.app.goo.gl/DyQyzF641B7ybuG59">View on Map</a></div>
                        <div className="arrow-marks">
                            <img src={Arrow} alt="Arrow" />
                        </div>
                    </div>
                </div>

                <div className="location-card">
                    <div className="top-section">
                        <div className="location-info">
                            <div className="location-icon">
                                <img src={Logo} alt="Location" />
                            </div>
                            <div className="location-details">
                                <div className="location-details-place">Texas</div>
                                <div className="location-details-country">USA</div>
                            </div>
                        </div>
                    </div>
                    <div className="middle-section">
                        <div><span>Address</span>: 8195 S Custer Rd, Suite 200C, Frisco, TX 75035</div>
                    </div>
                    <div className="bottom-section">
                        <div className="view-maps-button">
                            <a target="_blank" href="https://maps.app.goo.gl/Zvy5CbnabrWcDvvV9">View on Map</a></div>
                        <div className="arrow-marks">
                            <img src={Arrow} alt="Arrow" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Branches;
