// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import Module_image from '../GlobalModules/Image_hs';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Transform Workforce Efficiency with Streamlined",
    headingSpan: " HR & Payroll Management",
    subtext: "Simplify HR and payroll functions with an integrated system that manages recruitment, employee data, and payroll processing. "
};

const features = [
    { question: 'Employee Data Management', answer: 'Centralize and organize employee records for easy access and efficient management. This feature consolidates all relevant employee information, such as personal details, job roles, and work history, into a single system, facilitating streamlined data management and quick access to essential information.' },
    { question: 'Payroll Automation', answer: 'Automate the calculation of payroll, deductions, and disbursements to ensure accuracy and efficiency. This feature reduces manual errors by automatically processing payroll transactions, handling tax calculations, and managing employee payments, resulting in timely and accurate payroll processing.' },
    { question: 'Leave and Attendance Management', answer: 'Track employee attendance, leave requests, and working hours with precision. This feature provides tools for monitoring attendance patterns, managing leave accruals, and recording working hours, ensuring accurate payroll calculations and adherence to company policies.' },
    { question: 'Recruitment Management', answer: 'Streamline the hiring process with comprehensive applicant tracking and onboarding tools. This feature simplifies recruitment by managing job postings, tracking applicants through the hiring stages, and facilitating onboarding processes, resulting in a more efficient and organized hiring workflow.' },
    { question: 'Performance Appraisals', answer: 'Conduct detailed performance reviews and manage employee appraisals effectively. This feature supports performance management by providing tools to evaluate employee performance, set objectives, and track progress, helping to align individual contributions with organizational goals' },
    { question: 'Benefits Administration', answer: 'Manage employee benefits such as insurance, bonuses, and allowances seamlessly. This feature enables efficient administration of employee benefits, including enrollment, changes, and tracking, ensuring that benefits are accurately administered and aligned with company policies.' },
    { question: 'Compliance Management', answer: 'Ensure adherence to labor laws and regulatory requirements with automated compliance tools. This feature helps maintain compliance by providing tools to manage legal and regulatory obligations, track changes in labor laws, and ensure that HR practices align with legal requirements.' },
    { question: 'Training and Development', answer: 'Oversee employee training programs and development plans to enhance skills and career growth. This feature supports training management by providing tools to schedule and track training activities, manage development plans, and assess the impact of training on employee performance.' },
    { question: 'Employee Self-Service', answer: 'Offer a self-service portal for employees to access pay slips, apply for leaves, and manage personal information. This feature empowers employees by providing direct access to their HR-related information and self-service options, enhancing convenience and reducing administrative workload.' },

];

const HrAndPayroll = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                    <Row className="ERP_alignmentModules" gutter={16}>
                        <Col
                            xs={{ span: 24, order: 2 }} // Span 24 and second on extra small screens
                            sm={{ span: 24, order: 2 }} // Span 24 and second on small screens
                            md={{ span: 24, order: 2 }} // Span 13 and first on medium screens
                            lg={{ span: 13, order: 1 }} // Span 13 and first on large screens
                            xl={{ span: 13, order: 1 }} // Span 13 and first on extra large screens
                        >
                            <Module_image />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }} // Span 24 and first on extra small screens
                            sm={{ span: 24, order: 1 }} // Span 24 and first on small screens
                            md={{ span: 24, order: 1 }} // Span 11 and second on medium screens
                            lg={{ span: 11, order: 2 }} // Span 11 and second on large screens
                            xl={{ span: 11, order: 2 }} // Span 11 and second on extra large screens
                        >
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>

                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}

        </React.Fragment>
    );
};

export default (HrAndPayroll);
