import React from "react";
import { } from "react-router";
import { Row, Col, Button, Card } from "antd";
import "./Blog.scss";
import Automation from "./Automation/Automation";


class Blog extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Automation />
            </React.Fragment>
        );
    }
}
export default (Blog);

