import React from "react";
import { Row, Col } from 'antd';
import Source_img from './Svg_files/communication hs image.svg'
import Logo_img1 from './Svg_files/customer-service.svg';
import Logo_img2 from './Svg_files/invoice.svg';
import Logo_img3 from './Svg_files/utilization (1) (1).svg';
import Logo_img4 from './Svg_files/compliance.svg';

import Production from './Svg_files/satisfaction (1) (2).svg'
import Operation from "./Svg_files/operational-cost 1.svg";
import Quality from './Svg_files/quality-service (2).svg'
import resource from './Svg_files/regulatory (1) 1.svg'

import Analytics from './Svg_files/communication ben image.svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "Customer Service Management", subtext: "Provides tools to manage customer interactions, service requests, and issue resolution, enhancing customer satisfaction and loyalty.", logo: Logo_img1 },
        { id: 2, heading: "Automated Billing and Invoicing", subtext: "Automates billing cycles, reducing manual errors, improving accuracy, and speeding up payment processing.", logo: Logo_img2 },
        { id: 3, heading: "Network Resource Management", subtext: "Optimizes the use of network resources, ensuring efficient service delivery and minimizing downtime.", logo: Logo_img3 },
        { id: 4, heading: "Regulatory Compliance Management", subtext: "Monitors compliance with industry regulations, reducing risk and ensuring adherence to standards.", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "Simplify Customer Management with an Integrated CRM", subtext: "Centralizes customer data and interactions for improved service quality and engagement." },
        { id: 2, heading: "Automate Billing to Reduce Errors and Delays", subtext: "Streamlined billing processes increase accuracy, reduce operational costs, and enhance cash flow." },
        { id: 3, heading: "Optimize Network Resources for Better Service Quality", subtext: "Efficiently manage network assets and operations, reducing downtime and improving service delivery." },
        { id: 4, heading: "Ensure Regulatory Compliance with Built-in Tools", subtext: "Stay compliant with regulatory requirements effortlessly through automated compliance monitoring and reporting." }
    ],
    benefitsCards: [
        { id: 1, heading: "Enhanced Customer Satisfaction", subtext: "Improved service management and faster issue resolution lead to higher customer satisfaction and retention.", image: Production },
        { id: 2, heading: "Reduced Operational Costs", subtext: "Automation and streamlined processes reduce manual tasks, cutting down operational costs significantly.", image: Operation },
        { id: 3, heading: "Improved Service Quality", subtext: "Better network management and optimized resources result in reliable and high-quality service delivery.", image: Quality },
        { id: 4, heading: "Better Regulatory Compliance", subtext: "Automated compliance tracking helps avoid penalties and ensures adherence to industry standards.", image: resource }
    ]
};

function Communication() {
    const questions = [
        { question: 'Can Compreo manage large volumes of customer data? ', answer: "Yes, Compreo's CRM module efficiently handles large volumes of customer data, ensuring seamless service management." },
        { question: 'How does Compreo help with regulatory compliance?', answer: 'Compreo provides automated compliance tracking and reporting tools to ensure adherence to industry standards.' },
        { question: 'Does Compreo support mobile billing? ', answer: 'Yes, Compreo supports mobile billing, enabling customers to receive and pay bills through mobile platforms.' },
        { question: 'Can Compreo integrate with existing communication networks?', answer: 'Yes, Compreo integrates with existing networks, providing a unified platform for managing all communication services.' },
        { question: 'How does Compreo optimize network management?', answer: 'Compreo offers tools to monitor, manage, and optimize network resources for efficient service delivery.' },
        { question: 'Does Compreo support multi-channel customer service? ', answer: 'Yes, Compreo integrates multiple service channels for unified customer support management.' }
    ];

    return (
        <div className="industry-parent">
             <div className="industry-your-future">
                <div className="industry-your-future-container">
                    {/* Section for Heading, Subtext, and Image */}
                    <div className="industry-your-future-text-section">

                        <div className="industry-your-future-image">
                            <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                            <div className="industry-your-future-text-container">
                                <div className="industry-your-future-text-heading">
                                COMMUNICATION INDUSTRY                    </div>
                                <div className="industry-your-future-text-subtext">
                                "Streamline Communication Processes with Integrated ERP"                    </div>
                            </div>
                        </div>
                    </div>

                    {/* Section for Description and Cards */}
                    <div className="industry-your-future-content-section">
                        <div className="industry-your-future-description">
                        Compreo ERP provides telecom and communication companies with a unified platform to manage customer service, billing, network management, and compliance, optimizing service delivery and customer engagement.                      </div>
                        <div className="industry-your-future-cards">
                            <Row gutter={[16, 16]} justify="center">
                                {data.industryFutureCards.map(card => (
                                    <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                        <div className="industry-your-future-cards-container">
                                            <div className="industry-your-future-cards-container-logo">
                                                <img src={card.logo} alt="Logo_alt" />
                                            </div>
                                            <div className="industry-your-future-cards-container-heading">
                                                {card.heading}
                                            </div>
                                            <div className="industry-your-future-cards-container-subtext">
                                                {card.subtext}
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span>Communication</span></div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container-left-heading">Benefits</div>

                <div className="industry-benefits-container">
                   
                                    <Row gutter={[16, 16]}>
                                        {data.benefitsCards.map((card) => (
                                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                                <div className="industry-benefits-container-left-cards-image">
                                                    <img className="industry-benefits-container-left-cards-image-logo" src={card.image} alt="alternative" />
                                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                               
                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
        </div>
    );
}

export default Communication;
