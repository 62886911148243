// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import ArrowIcon from './Svg_files/Arrow 28.svg';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import { Collapse } from 'antd';
import Module_image from '../GlobalModules/Image_hs';

const { Panel } = Collapse;

// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Drive Digital Sales with Next-Gen ",
    headingSpan: "E-Commerce Portal",
    subtext: "A powerful platform to manage online sales, inventory, and customer interactions, providing a seamless ordering experience. It integrates various tools to enhance user experience, streamline operations, and maximize sales.  "
};

const features = [
    { question: 'Product Catalog Management', answer: 'Manage your product listings with detailed categories and attributes, ensuring that customers can easily browse and find items. This feature enhances organization and presentation, helping to improve the shopping experience and drive sales by showcasing products effectively. ' },
    { question: 'Secure Payment Gateway Integration', answer: ' Integrate multiple secure payment methods, including credit cards and digital wallets. This ensures transactions are processed safely, builds customer trust, and minimizes cart abandonment, ultimately increasing conversion rates and customer satisfaction.' },
    { question: 'Customer Account Management', answer: 'Enable customers to create and manage accounts, track orders, and update preferences. This personalization enhances user experience by providing easy access to order history and account settings, improving customer engagement and loyalty. ' },
    { question: 'Order Tracking and Management', answer: 'Allow customers and administrators to track orders from placement through delivery. This feature provides visibility into the status of each order, improves customer service, and streamlines the management of the order fulfillment process.' },
    { question: 'SEO and Marketing Tools', answer: ' Utilize built-in SEO tools and marketing features to boost your online visibility. This includes optimizing product listings for search engines, running email campaigns, and integrating with social media to drive traffic and increase sales. ' },
    { question: 'Inventory Sync', answer: 'Synchronize inventory levels across all sales channels to maintain accuracy and prevent stock issues. This feature ensures that product availability is up-to-date, reducing the risk of overselling and improving inventory management. ' },
    { question: 'Promotions and Discounts', answer: 'Create and manage promotions, discount codes, and special offers with ease. This feature helps attract customers, encourage purchases, and drive sales during promotional periods by offering targeted incentives. ' },
    { question: 'Analytics and Reporting', answer: 'Access comprehensive reports on sales performance, customer behavior, and inventory metrics. This feature provides valuable insights for data-driven decision-making, helping businesses optimize strategies and improve overall performance. ' },
];


const EcommercePortal = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);


    const toggleFeature = (feature) => {
        setSelectedFeature(feature);
    };


    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                <Row className="ERP_alignmentModules" gutter={16}>
                        <Col
                            xs={{ span: 24, order: 2 }} // Span 24 and second on extra small screens
                            sm={{ span: 24, order: 2 }} // Span 24 and second on small screens
                            md={{ span: 24, order: 2 }} // Span 13 and first on medium screens
                            lg={{ span: 13, order: 1 }} // Span 13 and first on large screens
                            xl={{ span: 13, order: 1 }} // Span 13 and first on extra large screens
                        >
                            <Module_image />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }} // Span 24 and first on extra small screens
                            sm={{ span: 24, order: 1 }} // Span 24 and first on small screens
                            md={{ span: 24, order: 1 }} // Span 11 and second on medium screens
                            lg={{ span: 11, order: 2 }} // Span 11 and second on large screens
                            xl={{ span: 11, order: 2 }} // Span 11 and second on extra large screens
                        >
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
            
        </React.Fragment>
    );
};

export default (EcommercePortal);
