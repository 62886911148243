// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import Module_image from '../GlobalModules/Image_hs';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Revolutionize ",
    headingSpan: "Procurement",
    headingPart2: "  with Automated Supply Chain Solutions",
    subtext: "Streamline the procurement process with an integrated solution that manages supplier relationships, purchase orders, and approvals. "
};

const features = [
    { question: 'Supplier Management', answer: 'Maintain a comprehensive database of suppliers with ratings and performance tracking to manage relationships effectively. This feature supports better supplier evaluation, enhances procurement strategies, and ensures reliable sourcing of materials and services. ' },
    { question: 'Purchase Order Automation', answer: ' Automate the creation, approval, and tracking of purchase orders to streamline procurement processes. This feature reduces manual work, improves accuracy, and ensures timely order fulfillment by managing purchase orders efficiently. ' },
    { question: 'Approval Workflows', answer: ' Implement customizable workflows for purchase request approvals to ensure compliance with organizational policies. This feature facilitates structured approval processes, improves procurement control, and ensures adherence to established procedures. ' },
    { question: 'Cost Control and Analysis', answer: 'Monitor spending and identify cost-saving opportunities with detailed analysis of procurement costs. This feature supports budget management, helps reduce expenses, and provides insights into cost efficiencies and savings. ' },
    { question: 'Contract Management', answer: 'Manage supplier contracts effectively, including tracking renewals and expirations. This feature ensures compliance with contractual terms, improves contract administration, and provides alerts for critical contract milestones. ' },
    { question: 'Real-Time Inventory Integration', answer: 'Sync procurement systems with inventory management to trigger reordering when stock levels are low. This feature ensures timely replenishment, prevents stockouts, and maintains optimal inventory levels. ' },
    { question: 'Vendor Portal Integration', answer: 'Allow suppliers to view and manage their orders, invoices, and payments through a dedicated portal. This feature enhances supplier collaboration, streamlines communication, and facilitates efficient order and payment management. ' },
    { question: 'Audit Trail and Compliance', answer: 'Maintain a complete record of procurement activities for auditing purposes and compliance. This feature ensures transparency, supports regulatory adherence, and provides a comprehensive history of procurement transactions.' },
    { question: 'Reporting and Insights', answer: ' Generate comprehensive reports on procurement activities, supplier performance, and spending. This feature provides valuable insights for strategic decision-making, helps optimize procurement processes, and improves overall efficiency. ' },
];

const Procurement = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                <Row className="ERP_alignmentModules" gutter={16}>
                        <Col
                            xs={{ span: 24, order: 2 }} // Span 24 and second on extra small screens
                            sm={{ span: 24, order: 2 }} // Span 24 and second on small screens
                            md={{ span: 24, order: 2 }} // Span 13 and first on medium screens
                            lg={{ span: 13, order: 1 }} // Span 13 and first on large screens
                            xl={{ span: 13, order: 1 }} // Span 13 and first on extra large screens
                        >
                            <Module_image />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }} // Span 24 and first on extra small screens
                            sm={{ span: 24, order: 1 }} // Span 24 and first on small screens
                            md={{ span: 24, order: 1 }} // Span 11 and second on medium screens
                            lg={{ span: 11, order: 2 }} // Span 11 and second on large screens
                            xl={{ span: 11, order: 2 }} // Span 11 and second on extra large screens
                        >
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
            
        </React.Fragment>
    );
};

export default (Procurement);
