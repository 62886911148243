// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import Module_image from '../GlobalModules/Image_hs';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Simplify Complexity with Scalable ",
    headingSpan: " Project Planning",
    // headingPart2: " Excellence.",
    subtext: "Plan, schedule, and manage projects with advanced tools that help allocate resources, set timelines, and track progress effectively. "
};

const features = [
    { question: 'Gantt Charts and Timelines', answer: 'Use Gantt charts to visually plan and track project phases, timelines, and dependencies. This feature aids in scheduling, managing deadlines, and provides a clear overview of project progress. ' },
    { question: 'Resource Allocation', answer: 'Efficiently allocate resources based on availability and skill requirements. This feature ensures optimal use of resources, meets project demands, and enhances overall productivity. ' },
    { question: 'Milestone Tracking', answer: ' Set and monitor key project milestones to track progress and ensure timely completion. This feature helps manage critical project goals and keeps activities aligned with project timelines.' },
    { question: 'Budget Management', answer: 'Track and manage project budgets, expenses, and forecasts. This feature supports financial control, prevents budget overruns, and ensures efficient use of financial resources. ' },
    { question: 'Task Assignment and Management', answer: 'Create, assign, and track tasks and their progress. This feature supports effective task management, improves productivity, and ensures timely completion of project activities.' },
    { question: 'Collaboration Tools', answer: 'Include tools for real-time collaboration, such as chat and file sharing. This feature enhances teamwork, improves communication among project stakeholders, and supports efficient project execution. ' },
    { question: 'Risk Management', answer: 'Identify, assess, and mitigate potential project risks. This feature helps manage risks proactively, ensuring project success and reducing the likelihood of unforeseen issues. ' },
    { question: 'Time Tracking', answer: 'Monitor the time spent on tasks and activities to ensure accurate project billing and effective management of project resources. This feature supports time management and enhances project efficiency. ' },
    { question: 'Custom Reporting', answer: 'Generate customized reports on project progress, resource utilization, and costs. This feature provides tailored insights for strategic planning and supports informed decision-making. ' },
];

const ProjectPlanning = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                <Row className="ERP_alignmentModules" gutter={16}>
                        <Col
                            xs={{ span: 24, order: 2 }} // Span 24 and second on extra small screens
                            sm={{ span: 24, order: 2 }} // Span 24 and second on small screens
                            md={{ span: 24, order: 2 }} // Span 13 and first on medium screens
                            lg={{ span: 13, order: 1 }} // Span 13 and first on large screens
                            xl={{ span: 13, order: 1 }} // Span 13 and first on extra large screens
                        >
                            <Module_image />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }} // Span 24 and first on extra small screens
                            sm={{ span: 24, order: 1 }} // Span 24 and first on small screens
                            md={{ span: 24, order: 1 }} // Span 11 and second on medium screens
                            lg={{ span: 11, order: 2 }} // Span 11 and second on large screens
                            xl={{ span: 11, order: 2 }} // Span 11 and second on extra large screens
                        >
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}

        </React.Fragment>
    );
};

export default (ProjectPlanning);
