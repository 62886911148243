import React from 'react';
import { Col,Row  } from 'antd';
import Robot from './Svg_files/contact_us_holding_robot.svg';
import image from './Svg_files/contact us image.svg'
import Email from './Svg_files/email 8.svg';
import Phone from './Svg_files/phone-call 1.svg';
import LinkedIn from './Svg_files/linkdin.svg';
import twitter_x from './Svg_files/twitter_x.svg';
import youtube from './Svg_files/youtube.svg';
import './Contact.scss';

const ContactUs = () => {
  const onFinish = (event) => {
    event.preventDefault();
    // Handle form submission
    console.log('Form submitted');
  };

  return (
    <div className="contact-form-container">
      <Row>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <div className="contact-left">
          <div className="contact-left-1">Have questions? We have answers!</div>
          <div className="contact-left-2">
            <div className="contact-left-2-1">Get in Touch with us!!</div>
            <div className="contact-left-2-2">
              We'd love to hear from you! Whether you have questions, feedback, or need assistance, we're here to help. Please reach out, and our team will get back to you soon.
            </div>
          </div>
          <div className="contact-left-3">
            <div className="contact-left-3-left">
            <div className="contact-left-3-left-top">
              <img src={Email} alt="Email" />
              <div >Mail </div>
              </div>
              <div className="contact-left-3-left-bottom">
              <a className='e-mail_link' href="mailto:sales@compreo.ai">sales@compreo.ai </a>
              </div>
            </div>
            <div className="contact-left-3-right">
            <div className="contact-left-3-left-top">
              <img src={Phone} alt="Phone" />
              <div>Mobile Number</div>
              </div>
              <div className="contact-left-3-left-bottom">
              +91 9949212962             
                 </div>
            </div>
          </div>
          <div className="contact-left-4">
            <div className="contact-left-4-text">Follow Us On:</div>
            <a target="_blank" href="https://www.linkedin.com/company/compreoai/">
              <img src={LinkedIn} alt="LinkedIn" />
            </a>
            <a target="_blank" href='https://www.youtube.com/@CompreoAI'>
              <img src={youtube} alt="youtube" />
            </a>
            <a target="_blank" href='https://x.com/CompreoAI'>
              <img src={twitter_x} alt="twitter_x" />
            </a>
          </div>
        </div>
      </Col>

      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <div className='contact-right'>
          <img src={image} alt="Banner" />
        </div>
        {/* <div className="form-container">
          <form onSubmit={onFinish}>
            <div className="form-row">
              <div className="input-group">
                <input type="text" id="firstName" name="firstName" placeholder="First Name" />
              </div>
              <div className="input-group">
                <input type="text" id="lastName" name="lastName" placeholder="Last Name" />
              </div>
            </div>
            <div className="form-row">
              <div className="input-group">
                <input type="text" id="companyName" name="companyName" placeholder="Company Name" />
              </div>
              <div className="input-group">
                <input type="text" id="designation" name="designation" placeholder="Designation" />
              </div>
            </div>
            <div className="form-row">
              <div className="input-group">
                <input type="email" id="mailId" name="mailId" placeholder="Mail ID" />
              </div>
              <div className="input-group">
                <input type="tel" id="mobileNumber" name="mobileNumber" placeholder="Mobile Number" />
              </div>
            </div>
            <div className="input-group full-width">
              <textarea id="description" name="description" placeholder="Description (Optional)"></textarea>
            </div>
            <div className="input-group-submit">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
        <div className="robot">
          <img src={Robot} alt="robot" />
        </div> */}



      </Col>
      </Row>
    </div>
  );
};

export default ContactUs;








