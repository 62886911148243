import React from 'react';
import { Row, Col, Card } from 'antd';
import "../ERP/Erp.scss"
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import EasyBusiness from '../LandingPage/EasyBusiness/EasyBusiness';
import EndtoendClouderp from "../LandingPage/EndtoendClouderp/EndtoendClouderp";
import Image222 from './Svg_files/BackgroundImage.svg'
import Customize from './Svg_files/customize.svg';
import Engineering from './Svg_files/engineering.svg';
import Analytics from './Svg_files/analytics.svg';
import Report from './Svg_files/report.svg';
import Coverage from './Svg_files/integration.svg';
import MobileApli from './Svg_files/check.svg';
import Image1 from './Svg_files/image1.svg';
import Arrow from './Svg_files/Arrow 1.svg'
import CompreoImg from './Svg_files/CompreoOffers.svg';
import Customization from './Svg_files/Customization.svg';
import Integration from './Svg_files/Integration copy.svg';
import CustomerService from './Svg_files/CustomerService.svg';
import Rectangle1 from './Svg_files/Rectangle1.svg';
import Rectangle2 from './Svg_files/Rectangle2.svg';
import Rectangle3 from './Svg_files/Rectangle3.svg';
import EsaybusinessBackground from '../EsaybusinessBackground/EsaybusinessBackground';


const Pos = () => {

    const features = [
        {
            imgSrc: Customize,
            heading: "Intuitive User Interface ",
            subtext: "Our POS system features an intuitive, user-friendly interface designed for ease of use across all staff levels. It streamlines daily operations, reduces training time, and minimizes errors, enhancing overall efficiency and providing a superior customer experience."
        },
        {
            imgSrc: Engineering,
            heading: "Flexible Payment Solutions ",
            subtext: "Our POS system accommodates various payment methods, including credit/debit cards, cash, and split payments. This flexibility ensures a seamless checkout experience, enhances customer convenience, and maintains smooth transaction processes, contributing to improved satisfaction and operational efficiency."
        },
        {
            imgSrc: Analytics,
            heading: "Customized Invoicing ",
            subtext: "Elevate your brand with customizable invoicing features. Select from a range of pre-designed templates that can incorporate your company’s logo and branding. This customization streamlines billing, enhances professional presentation, and ensures that invoices reflect your business’s identity."
        },
        {
            imgSrc: Report,
            heading: "Comprehensive Sales Reporting",
            subtext: "Access advanced sales reporting with our POS system. Generate detailed reports on sales trends, revenue, and customer behavior. Customize and analyze data to make informed decisions, identify growth opportunities, and optimize your sales strategies for better profitability and success."
        },

    ];

    const Subheading = '                                Built for growth. Designed with scalability in mind, the system has the inherent potential to enable business expansion as your needs change.'

    const cardData = [
        { title: 'Cloud-Native for Scalability and Stability  ', content: 'Leverage a cloud-native POS system that offers superior stability, security, and scalability, enhancing your competitive advantage and ensuring business resilience. ' },
        { title: 'Efficient Deployment and Upgrades  ', content: 'Deploy software updates and new integrations seamlessly across all locations, ensuring consistency and efficiency. ' },
    ];

    const cards = [
        {
            title: 'OCR Capability',
            description: 'Transform documents into data',
            image: Rectangle1,

        },
        {
            title: 'Automation Tools',
            description: 'Let the software handle repetitive tasks',
            image: Rectangle2,
        },
        {
            title: 'Real-Time Analytics',
            description: 'Make informed decisions faster',
            image: Rectangle3,
        },
    ];

    const questions = [
        { question: 'What devices are compatible with the Compreo POS system? ', answer: 'The Compreo POS system is designed to work seamlessly on a variety of devices, including tablets and smartphones, and is compatible with both Android and iOS platforms. ' },
        { question: 'What payment methods does the POS system support? ', answer: 'The POS system supports multiple payment methods, including cards and cash payments, providing flexibility and convenience for both you and your customers. ' },
    ];

    return (
        <div className='about-parent'>
            <div className='startEngaging-parent'>
                <div className='startEngaging-container' style={{
                    backgroundImage: `url(${Image222})`,
                }}>
                    <div
                        className='startEngaging-container-text'>
                        <div className='startEngaging-container-heading'>
                            Struggling with complex sales? Compreo’s POS integrates seamlessly across channels.
                        </div>
                        <div className='startEngaging-container-subtext'>
                            Empower your business with Compreo's comprehensive, integrated point-of-sale (POS) solution that streamlines operations, enhances customer experiences, and drives growth. Our POS system offers real-time inventory management, seamless payment processing, ensuring efficiency and scalability to adapt to your evolving business needs effortlessly.
                        </div>
                    </div>
                </div>
            </div>
            <div className="kf-container">
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={10} lg={10} xl={10} className="kf-container-left">
                        <div className="kf-container-heading">Key Features</div>
                        <div className="kf-container-subtext">
                            Transform the way you manage sales, inventory, and customer interactions with our all-in-one POS system. Whether you’re running a bustling retail store or a dynamic restaurant, our POS solution offers a robust, user-friendly platform that adapts to your business needs, boosts operational efficiency, and supports growth. Experience a seamless blend of technology and practicality with our state-of-the-art POS system.
                        </div>
                    </Col>
                    <Col xs={24} md={14} lg={14} xl={14} className="kf-cards">
                        <Row gutter={[24, 24]} className="kf-cards-parent">
                            {features.map((feature, index) => (
                                <Col
                                    xs={24}
                                    sm={12}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    key={index}
                                    className="kf-cards-container"
                                >
                                    <div className="kf-cards-container-img">
                                        <img src={feature.imgSrc} alt={feature.heading} />
                                    </div>
                                    <div className="kf-cards-container-heading">
                                        {feature.heading}
                                    </div>
                                    <div className="kf-cards-container-subtext">
                                        {feature.subtext}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="catalyst-container">
                <div className="catalyst-content">
                    <div className="catalyst-points">
                        <ul className="catalyst-points-ul">
                            <li>Experience the power of Compreo POS to streamline sales operations with unmatched speed and efficiency, optimizing daily transactions and inventory management effortlessly.​</li>
                            <li>Compreo POS is designed to fit the unique business of any size, seamlessly integrating into your existing systems and adapting to your specific demands. ​</li>
                            <li>The intuitive interface and robust features of Compreo POS simplify customer interactions and operational tasks, allowing you to manage business activities with ease and minimal training.</li>
                            <li>Whether you run a small retail store or a large enterprise, Compreo POS delivers scalable performance, enhancing overall efficiency and boosting customer satisfaction across all levels of your business.​</li>
                        </ul>
                    </div>
                    <div className="catalyst-title spacingAlignCatalist">
                        <div className="catalyst-title-heading">Catalyst for your Business Operations</div>
                        <div className="catalyst-image">
                            <img src={Image1} alt="Catalyst" />
                        </div>
                    </div>

                </div>
            </div>
            <div className='CompreoOfferAlignment'>
                <Row gutter={[16, 16]} className='OffersAlignment'>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={10}
                        xl={11}
                    >
                        <div className='OffersAlignment-image'>
                            <img src={CompreoImg} alt="CompreoOffers" className="CompreoOffers" />
                        </div>
                    </Col>
                    <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={14}
                        xl={12}
                    >
                        <div >
                            <h2 className='ProductStandstext'>What makes our product stands out from the rest?</h2>
                            <h4 className='BusinesOffersText'>Struggling with complex sales? Our POS integrates seamlessly across channels.</h4>
                            <div className="catalyst-points backrdnone">
                                <ul className="catalyst-points-ul listSPacing">
                                    <li >
                                        {/* <img src={Customization} alt="CompreoOffers" className='OfferIcon' /> */}
                                        Supports single and multi-location sales.
                                    </li>
                                    <li >
                                        {/* <img src={Integration} alt="CompreoOffers" className='OfferIcon' /> */}
                                        Compatible with Android and iOS devices.
                                    </li>
                                    <li >
                                        {/* <img src={CustomerService} alt="CompreoOffers" className='OfferIcon' /> */}
                                        Enables effortless product scanning on-the-go.
                                    </li>
                                    <li>
                                        Facilitates quick customer billing and mobility.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <EsaybusinessBackground cardData={cardData} Subheading={Subheading} />

            {/* <div className="my-background">

                <h2 className='HeadingAlign'>COMPREO.AI brings cutting-edge technology to your business:</h2>
                <div className="three-card-view">
                    <Row gutter={[16, 16]} className='CardAlignHoverable'>
                        {cards.map((card, index) => (
                            <Col xs={24} sm={12} md={7} key={index}>
                                <Card
                                    hoverable
                                    cover={<img alt={card.title} src={card.image} />}
                                    className="custom-card"
                                >
                                    <Card.Meta style={{ color: '#fff !important' }} className='CardData' title={card.title} description={card.description} />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div> */}
            {/* <EndtoendClouderp /> */}
            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
        </div>
    );
};

export default Pos;
