import React from "react";
import Heromodules from './Svg_files/industries-hs-image.svg';
import './Image_hs.css'

const Module_image = () => {
    return (
        <div>
            <div className='module-top-left'>
                <img src={Heromodules} alt='Employyee--' />
            </div>
        </div>
    );
}

export default (Module_image);