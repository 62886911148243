import React, { useState } from "react";

import "./Autmation.scss";
import blogImage from "./Svg_files/image.svg";
import InventoryIssues1 from "./Svg_files/InventoryIssues-1.svg";
import InventoryIssues2 from "./Svg_files/InventoryIssues-2.svg";
import InventoryIssues3 from "./Svg_files/InventoryIssues-3.svg";
import Visibility from "./Svg_files/Visibility.svg";
import DataVisibility1 from "./Svg_files/DataVisibility1.svg";
import DataVisibility2 from "./Svg_files/DataVisibility2.svg";
import DataVisibility3 from "./Svg_files/DataVisibility3.svg";
import leftArrow from "./Svg_files/Keyboard arrow left.svg";
import BlogFeaturelist from "../../GlobalModules/BlogFeaturelist";

// const content = {
//     headingPart1: "Transform Member Engagement with our Dynamic",
//     headingSpan: " Club Management",
//     subtext: "An all-in-one solution to manage memberships, events, and activities for clubs and associations, improving engagement and efficiency. "
// };

const features = [
  {
    question: "Tackling Inventory Management Challenges with Automation ",
    answer: (
      <>
        <div>
          In today’s competitive landscape, manufacturing and distribution
          companies face increasing pressure to optimize operations, reduce
          waste, and improve productivity. Yet two pervasive
          challenges—efficient inventory management and real-time data
          visibility—often stand in the way. Without proper tools, these issues
          can lead to lost revenue, inefficiencies, and poor customer
          satisfaction. Let’s explore how automation, through tools like
          Compero.ai ERP, can address these challenges and seamlessly bridge the
          gap between manufacturing and distribution. 
        </div>
        <span style={{ color: "red" }}>
          1. Tackling Inventory Management Challenges with Automation 
        </span>
        <div>
          Inventory mismanagement is one of the top concerns for manufacturing
          and distribution businesses, with studies showing that{" "}
          <b>
            43% of small businesses in the U.S. either overstock or understock
            their inventory (QuickBooks, 2023).
          </b>{" "}
          Overstocking leads to wasted capital, while understocking can result
          in missed sales opportunities and frustrated customers. 
        </div>
        <h2>Example Scenario</h2>
        <div>
          Consider a manufacturing company that produces components for
          automotive parts. Due to fluctuating demand and outdated tracking
          methods, the company often runs into stockouts, delaying production
          lines and causing shipping delays. By the time the inventory team
          recognizes the shortage, production schedules are impacted, and
          customer satisfaction is at risk. 
        </div>
      </>
    ),
  },
  { question: "How Compero.ai ERP Solves Inventory Issues ", answer: "  " },
  { question: "Overcoming Lack of Real-Time Data Visibility", answer: " " },
  {
    question: "How Compero.ai ERP Brings Real-Time Data Visibility",
    answer: " ",
  },
  { question: "Bridging the Gap with Compero.ai", answer: " " },
];

const posts = [
    {
      title: "Tackling Inventory Management Challenges with Automation",
      id: "inventory-management",
    },
    {
      title: "How Compero.ai ERP Solves Inventory Issues",
      id: "compero-solves",
    },
    {
      title: "Overcoming Lack of Real-Time Data Visibility",
      id: "data-visibility",
    },
    {
      title: "How Compero.ai ERP Brings Real-Time Data Visibility",
      id: "compero-data-visibility",
    },
    { title: "Bridging the Gap with Compero.ai", id: "bridging-gap" },
  ];

const Automation = () => {
  const [activePost, setActivePost] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(features[0]);
  const toggleFeature = (feature) => {
    setSelectedFeature(selectedFeature === feature ? null : feature);
  };

  const handlePostClick = (id) => {
    setActivePost(id); 
    const targetElement = document.getElementById(id);
    if (targetElement) {
      const yOffset = -100; // Adjust for navbar height
      const yPosition =
        targetElement.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  };

 

  return (
    <div>
      <h1 className="our-blog-heading">
        From Manufacturing to Distribution: Bridging the Gap with Automation
      </h1>
      <div className="blog-space-align">
        <div></div>
        <p>Posted on | Dec 02 2024</p>
        <p>By Compreo Team</p>
        <p>4 min Read</p>
        <div></div>
      </div>
      <div className="our-blog-hero-image">
        <img src={blogImage} alt="Blog_img" style={{ width: "80%" }} />
      </div>

      <div className="module">
        <div className="module-parent">
          {/* <BlogFeaturelist
            features={features}
            selectedFeature={selectedFeature}
            onSelectFeature={toggleFeature}
          /> */}

          <div className="previousposts-container">
            <aside className="previousposts-sidebar">
              {/* <h2>Previous Posts</h2> */}
              <div className="previous-posts">
                {posts.map((post, index) => (
                  <div
                    key={index}
                    className={`post-item ${
                      activePost === post.id ? "active" : ""
                    }`}
                    onClick={() => handlePostClick(post.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="image-container">
                      <img
                        src={leftArrow}
                        alt="Left_arrow"
                        className="left-arrow-img"
                      />
                    </div>
                    <div>{post.title}</div>
                  </div>
                ))}
              </div>
            </aside>

            <main className="previousposts-main-content">
              <p>
                In today’s competitive landscape, manufacturing and distribution
                companies face increasing pressure to optimize operations,
                reduce waste, and improve productivity. Yet two pervasive
                challenges—efficient inventory management and real-time data
                visibility—often stand in the way. Without proper tools, these
                issues can lead to lost revenue, inefficiencies, and poor
                customer satisfaction. Let’s explore how automation, through
                tools like Compero.ai ERP, can address these challenges and
                seamlessly bridge the gap between manufacturing and
                distribution. 
              </p>

              <h2 id="inventory-management">
                1. Tackling Inventory Management Challenges with Automation
              </h2>
              <p>
                Inventory mismanagement is one of the top concerns for
                manufacturing and distribution businesses, with studies showing
                that{" "}
                <span className="highlight">
                  43% of small businesses in the U.S. either overstock or
                  understock their inventory (QuickBooks, 2023).
                </span>{" "}
                Overstocking leads to wasted capital, while understocking can
                result in missed sales opportunities and frustrated customers. 
              </p>

              <h3>Example Scenario</h3>
              <p>
                Consider a manufacturing company that produces components for
                automotive parts. Due to fluctuating demand and outdated
                tracking methods, the company often runs into stockouts,
                delaying production lines and causing shipping delays. By the
                time the inventory team recognizes the shortage, production
                schedules are impacted, and customer satisfaction is at risk. 
              </p>
            </main>
          </div>
        </div>
      </div>

      <div className="InventoryIssues-container">
        <h1 id="compero-solves">How Compero.ai ERP Solves Inventory Issues</h1>

        <p>
          With Compero.ai, this company could set up real-time inventory
          tracking and automated stock replenishment. Here’s how it can
          transform their operations: 
        </p>

        <div className="InventoryIssues-grid">
          <div className="InventoryIssues-card">
            <img src={InventoryIssues1} alt="Automated Stock Management" />
            <h3>Automated Stock Replenishment</h3>
            <p>
              Compero.ai allows users to set minimum stock thresholds. When
              inventory dips below these levels, the system sends automated
              alerts, ensuring timely reordering and reducing the chance of
              stockouts. 
            </p>
          </div>

          <div className="InventoryIssues-card">
            <img src={InventoryIssues2} alt="Demand Forecasting" />
            <h3>Demand-Driven Forecasting</h3>
            <p>
              Using predictive analytics, Compero.ai analyzes sales history and
              seasonal trends, helping companies like our automotive component
              manufacturer predict demand accurately.
              <span>
                {" "}
                A recent survey showed that demand-driven forecasting can reduce
                excess inventory by 15% to 20% (Gartner, 2022).
              </span>
            </p>
          </div>

          <div className="InventoryIssues-card">
            <img src={InventoryIssues3} alt="Cost Reduction" />
            <h3>Reduction in Holding Costs</h3>
            <p>
              By maintaining optimal stock levels, companies reduce waste and
              holding costs, keeping inventory expenses under control. 
            </p>
          </div>
        </div>
      </div>

      <div className="Visibility-container">
        <div className="Visibility-header">
          <h1>
            These tools lead to higher efficiency and better control, saving
            money and streamlining the order-to-delivery process. 
          </h1>
        </div>
        <div className="Visibility-section">
          <img src={Visibility} alt="visibility-image" />

          <div className="Visibility-content">
            <h2 id="data-visibility">
              2. Overcoming Lack of Real-Time Data Visibility
            </h2>

            <p>
              Lack of real-time visibility across departments creates
              inefficiencies and delays. According to a survey by McKinsey,{" "}
              <span className="highlight">
                lack of data visibility is a leading factor in 61% of missed
                manufacturing deadlines.
              </span>{" "}
              When production and distribution teams operate in silos,
              information becomes fragmented, slowing down decision-making and
              often resulting in costly errors.
            </p>

            <h3>Example Scenario</h3>
            <p>
              A distribution company handling large volumes of consumer
              electronics frequently faces issues with backlogs due to
              miscommunication between teams. Without access to real-time
              production data, the distribution team often overestimates
              available inventory, leading to delayed shipments and cancelled
              orders.
            </p>
          </div>
        </div>
      </div>

      <div className="InventoryIssues-container">
        <h1 id="compero-data-visibility">
          How Compero.ai ERP Brings Real-Time Data Visibility
        </h1>

        <p>
          With Compero.ai’s centralized data system, companies like this
          distribution company can access real-time data on inventory,
          production schedules, and order status, enabling faster, data-driven
          decisions. Key features include: 
        </p>

        <div className="InventoryIssues-grid">
          <div className="InventoryIssues-card">
            <img src={DataVisibility1} alt="Automated Stock Management" />
            <h3>Enhanced Decision-Making</h3>
            <p>
              Compero.ai’s ERP platform consolidates data from manufacturing,
              inventory, and distribution channels, giving decision-makers a
              comprehensive, real-time view. This transparency empowers teams to
              make immediate adjustments based on actual inventory levels and
              production schedules. 
            </p>
          </div>

          <div className="InventoryIssues-card">
            <img src={DataVisibility2} alt="Demand Forecasting" />
            <h3>Cross-Departmental Collaboration</h3>
            <p>
              With centralized data, communication across departments becomes
              streamlined. For instance, if a production delay occurs, the
              distribution team receives immediate notifications, allowing them
              to adjust delivery schedules proactively. 
            </p>
          </div>

          <div className="InventoryIssues-card">
            <img src={DataVisibility3} alt="Cost Reduction" />
            <h3>Customizable Dashboards</h3>
            <p>
              Users can set KPIs relevant to their role, providing targeted
              insights on stock levels, distribution timelines, and sales
              forecasts. Research shows that{" "}
              <span>
                companies with real-time data visibility experience a 20% to 30%
                increase in productivity (Deloitte, 2022). 
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="Bridging-container">
        <h1 id="bridging-gap">Bridging the Gap with Compero.ai</h1>

        <p>
          Inventory management and data visibility are critical for
          manufacturing and distribution success. Through automation and
          real-time data access, Compero.ai ERP bridges these gaps, helping
          companies respond quickly to demand fluctuations, enhance customer
          satisfaction, and streamline the entire supply chain.
        </p>

        <div className="Bridging-stats">
          <p>
            Companies that invest in ERP systems like Compero.ai often
            experience up to{" "}
            <span className="highlight">
              40% reduction in inventory-related costs
            </span>{" "}
            and see an improvement in on-time delivery by as much as{" "}
            <span className="highlight">30% (Panorama Consulting, 2023)</span>.
            By automating routine tasks and fostering collaboration, Compero.ai
            positions manufacturing and distribution businesses to thrive in a
            competitive landscape.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Automation;
