import React from "react";
import { Row, Col } from 'antd';
import Source_img from './Svg_files/health care hs image.svg'
import Logo_img1 from './Svg_files/medical-record.svg';
import Logo_img2 from './Svg_files/medical-record (1).svg';
import Logo_img3 from './Svg_files/supply-chain (2).svg';
import Logo_img4 from './Svg_files/regulatory (1) (2).svg';

import Production from './Svg_files/patient 1.svg'
import Operation from "./Svg_files/operational-cost 1 (1).svg";
import Quality from './Svg_files/regulatory (1) (3).svg'
import resource from './Svg_files/utilization (2).svg'

import Analytics from './Svg_files/health care ben image.svg'
import GeneralQuestions from "../LandingPage/GeneralQuestions/GeneralQuestions";
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';


const data = {
    industryFutureCards: [
        { id: 1, heading: "Patient Management System", subtext: "Streamlines patient data, appointment scheduling, and billing, reducing administrative burdens and improving patient care and operational efficiency.", logo: Logo_img1 },
        { id: 2, heading: "Electronic Health Records (EHR) Integration", subtext: "Centralizes patient records for better coordination and care, ensuring data accuracy and easy access for healthcare providers.", logo: Logo_img2 },
        { id: 3, heading: "Inventory and Supply Chain Management", subtext: "Manages medical supplies efficiently, ensuring timely availability of resources and preventing stockouts and wastage.", logo: Logo_img3 },
        { id: 4, heading: "Regulatory Compliance Monitoring", subtext: "Automates compliance tracking with healthcare regulations, reducing risks and ensuring adherence to standards for patient safety and care.", logo: Logo_img4 }
    ],
    solutionCards: [
        { id: 1, heading: "Streamline Patient Information and Billing Processes", subtext: "Simplifies administrative tasks, reducing errors and wait times, enhancing patient experience and operational efficiency." },
        { id: 2, heading: "Integrate Electronic Health Records for Better Care", subtext: "Provides comprehensive patient data, improving treatment decisions and care coordination across departments." },
        { id: 3, heading: "Manage Inventory for Medical Supplies Efficiently", subtext: "Ensures optimal inventory levels and supply chain efficiency, reducing costs and improving resource availability." },
        { id: 4, heading: "Ensure Compliance with Healthcare Regulations", subtext: "Automates compliance checks and reporting, minimizing risks associated with non-compliance and ensuring patient safety." }
    ],
    benefitsCards: [
        { id: 1, heading: "Improved Patient Care", subtext: "Streamlined operations and accurate information enhance patient care quality and satisfaction.", image: Production },
        { id: 2, heading: "Reduced Operational Costs", subtext: "Automation reduces administrative workloads and costs, allowing more focus on patient care.", image: Operation },
        { id: 3, heading: "Enhanced Regulatory Compliance", subtext: "Automated tools help maintain compliance with healthcare standards, reducing penalties and enhancing trust.", image: Quality },
        { id: 4, heading: "Better Inventory Management", subtext: "Efficient inventory control minimizes stockouts and reduces wastage, optimizing resources.", image: resource }
    ]
};

function HealthIndustry() {
    const questions = [
        { question: 'Can Compreo integrate with existing EHR systems? ', answer: 'Yes, Compreo integrates seamlessly with existing EHR systems, ensuring smooth operations and comprehensive patient care.' },
        { question: 'Does Compreo support telehealth services? ', answer: 'Yes, Compreo supports telehealth services, enhancing patient access to care and improving service delivery' },
        { question: 'How does Compreo manage patient billing? ', answer: 'Compreo automates billing processes, reducing errors, speeding up payment cycles, and improving patient satisfaction.' },
        { question: 'Can Compreo help reduce administrative burdens? ', answer: 'Yes, by automating routine tasks, Compreo significantly reduces administrative burdens, allowing more focus on patient care.' },
        { question: 'Does Compreo assist with regulatory audits? ', answer: 'Yes, Compreo’s compliance tools help prepare and manage regulatory audits efficiently.' },
        { question: 'Can Compreo improve inventory tracking in hospitals? ', answer: "Yes, Compreo's inventory management system ensures accurate tracking of medical supplies and equipment, optimizing resource usage." }
    ];
    return (
        <div className="industry-parent">
            <div className="industry-your-future">
                <div className="industry-your-future-container">
                    {/* Section for Heading, Subtext, and Image */}
                    <div className="industry-your-future-text-section">
                       
                        <div className="industry-your-future-image">
                            <img src={Source_img} alt="Source" style={{ width: '100%' }} />
                            <div className="industry-your-future-text-container">
                        <div className="industry-your-future-text-heading">
                        HEALTH CARE INDUSTRY                       </div>
                        <div className="industry-your-future-text-subtext">
                        ""Transform Healthcare Management with our Robust ERP"                      </div>
                        </div>
                        </div>
                    </div>

                    {/* Section for Description and Cards */}
                    <div className="industry-your-future-content-section">
                        <div className="industry-your-future-description">
                        Compreo ERP offers healthcare providers a platform to manage patient information, billing, inventory, and compliance, ensuring quality care and operational excellence.                      </div>
                        <div className="industry-your-future-cards">
                            <Row gutter={[16, 16]} justify="center">
                                {data.industryFutureCards.map(card => (
                                    <Col key={card.id} xs={24} sm={24} md={12} lg={6}>
                                        <div className="industry-your-future-cards-container">
                                            <div className="industry-your-future-cards-container-logo">
                                                <img src={card.logo} alt="Logo_alt" />
                                            </div>
                                            <div className="industry-your-future-cards-container-heading">
                                                {card.heading}
                                            </div>
                                            <div className="industry-your-future-cards-container-subtext">
                                                {card.subtext}
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <div className="industry-solution-parent">
                <div className="industry-solution-container">
                    <div className="industry-solution-container-heading">Ultimate solution for <span>Health Care</span> </div>
                    <div className="industry-solution-container-cards">
                        <Row gutter={[16, 16]} justify="center">
                            {data.solutionCards.map(card => (
                                <Col key={card.id} xs={24} sm={12} md={12} lg={12}>
                                    <div className="industry-solution-container-cards-item">
                                        <div className="industry-solution-container-cards-item-heading">{card.heading}</div>
                                        <div className="industry-solution-container-cards-item-subtext">{card.subtext}</div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </div>
            <div className="industry-benefits-parent">
                <div className="industry-benefits-container-left-heading">Benefits</div>

                <div className="industry-benefits-container">
                   
                                    <Row gutter={[16, 16]}>
                                        {data.benefitsCards.map((card) => (
                                            <Col key={card.id} xs={24} sm={24} md={12} lg={12}>
                                                <div className="industry-benefits-container-left-cards-image">
                                                    <img className="industry-benefits-container-left-cards-image-logo" src={card.image} alt="alternative" />
                                                    <div className="industry-benefits-container-left-cards-heading">{card.heading}</div>
                                                    <div className="industry-benefits-container-left-cards-subtext">{card.subtext}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                               
                </div>
            </div>


            <GeneralQuestions questions={questions} />
            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
        </div>
    );
}

export default HealthIndustry;
