// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import Module_image from '../GlobalModules/Image_hs';

// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Elevate Project Success with Agile ",
    headingSpan: "Project Management",
    headingPart2: " Tools.",
    subtext: "Comprehensive project management tools to plan, execute, and monitor projects, ensuring efficient use of resources and timely delivery. "
};

const features = [
    { question: 'Task Management and Assignment', answer: 'Create, assign, and manage tasks with clearly defined deadlines and responsibilities. This feature supports effective project execution by ensuring tasks are allocated to appropriate team members, tracked for progress, and completed within the set timeframes, contributing to project success. ' },
    { question: 'Collaboration and Communication', answer: 'Facilitate seamless team collaboration through integrated communication tools. This feature enhances teamwork by providing channels for messaging, file sharing, and discussions, ensuring that all team members are aligned, informed, and able to work together efficiently on project activities.  ' },
    { question: 'Budget and Cost Management', answer: 'Track and manage project budgets and costs to avoid overruns and ensure financial control. This feature supports budgeting by providing tools to monitor expenditures, compare actual costs against budgeted amounts, and manage financial resources effectively to keep projects within budget.' },
    { question: 'Resource Management', answer: 'Allocate and optimize project resources, including human capital and materials, to maximize efficiency and effectiveness. This feature helps ensure that resources are used effectively by tracking availability, utilization, and performance, and making adjustments to improve project outcomes. ' },
    { question: 'Milestone Tracking', answer: 'Monitor progress against key project milestones to ensure timely completion. This feature provides tools for setting, tracking, and reviewing milestones, helping to ensure that critical project goals are achieved on schedule and any delays are addressed promptly.' },
    { question: 'Risk and Issue Tracking', answer: ' Identify, track, and manage project risks and issues to mitigate potential impacts. This feature supports proactive risk management by providing tools for assessing, tracking, and addressing risks and issues that could affect project success. ' },
    { question: 'Time Tracking', answer: 'Record and manage time spent on tasks for accurate billing and analysis. This feature helps ensure that time entries are tracked efficiently, providing accurate data for billing clients, analyzing project performance, and managing time resources effectively. ' },
    { question: 'Reporting and Dashboards', answer: 'Utilize real-time dashboards to monitor project status, progress, and financial health. This feature provides visual insights into key project metrics, enabling stakeholders to track performance, identify trends, and make informed decisions based on up-to-date information.' },
    { question: 'Document Management', answer: 'Centralize the storage and management of project documents and files. This feature ensures that all project-related documents are organized, accessible, and securely stored, supporting efficient document handling and collaboration throughout the project lifecycle.' },
    
];

const ProjectManagement = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
           <div className='module-top'>
                <div className='module-top-container'>
                <Row className="ERP_alignmentModules" gutter={16}>
                        <Col
                            xs={{ span: 24, order: 2 }} // Span 24 and second on extra small screens
                            sm={{ span: 24, order: 2 }} // Span 24 and second on small screens
                            md={{ span: 24, order: 2 }} // Span 13 and first on medium screens
                            lg={{ span: 13, order: 1 }} // Span 13 and first on large screens
                            xl={{ span: 13, order: 1 }} // Span 13 and first on extra large screens
                        >
                            <Module_image />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }} 
                            sm={{ span: 24, order: 1 }}
                            md={{ span: 24, order: 1 }} 
                            lg={{ span: 11, order: 2 }}
                            xl={{ span: 11, order: 2 }} 
                        >
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}

        </React.Fragment>
    );
};

export default (ProjectManagement);
