import React from 'react';
import Venkat_pic from './Svg_files/Venkat.svg';
import Anil_pic from './Svg_files/Anil Grandhi.svg';
import Email from './Svg_files/email 2.svg';
import Contact_us from "../LandingPage/Contact_us/Contact";
import Our_branches from "../LandingPage/Our_branches/branch";
import './OurTeam.scss';

const OurTeam = () => {
    return (
        <div className='business-leaders-parent'>
            <div className='business-leaders-container'>
                <div className='business-leaders-container-1'>
                    <div className='business-leaders-container-1-1'>
                        <div className='business-leaders-container-1-heading'>About our Leadership</div>
                    </div>
                    <div className='business-leaders-container-1-subtext'>
                        With a deep passion for innovation and a commitment to excellence, the our founder’s has been instrumental in shaping the company’s direction and driving its growth. Under their leadership, the company has grown from a simple idea into a thriving business that stands out in the industry for its dedication to quality, customer satisfaction, and forward-thinking solutions.
                    </div>
                </div>

                <div className='Leaders-cards'>
                    <div className="leader-card">
                        <div className="top-container">
                            <div className="left-section">
                                <img className="left-section-image" src={Venkat_pic} alt="Venkat Duukuri" />
                            </div>
                            <div className="right-section">
                                <div className="name">Venkat Duukuri</div>
                                <div className="designation">Founder & CEO</div>
                                <div className="social-media">
                                    <a href="https://www.linkedin.com/in/venkat-duddukuri-087b1b5a" target="_blank" rel="noopener noreferrer">
                                        <img src={Email} alt="Email" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-container">
                            Venkat Duddukuri is an engineer by qualification, hailing from a small town in India. His strong passion for knowledge and an insatiable curiosity about the world led him to New Zealand, where he pursued higher studies and gained valuable work experience. In 1999, he made a significant move to the USA, driven by the ambition to chase his dreams.
                        </div>
                    </div>

                    <div className="leader-card">
                        <div className="top-container">
                            <div className="left-section">
                                <img className="left-section-image" src={Anil_pic} alt="Anil Grandhi" />
                            </div>
                            <div className="right-section">
                                <div className="name">Anil Grandhi</div>
                                <div className="designation">Co-Founder & COO</div>
                                <div className="social-media">
                                    <a href="https://www.linkedin.com/in/anilgrandhi" target="_blank" rel="noopener noreferrer">
                                        <img src={Email} alt="Email" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-container">
                            Anil Grandhi has established himself as a respected figure in business, professional, and social circles across the USA. His illustrious career in finance has seen him work with renowned companies such as PWC, SunEdison, Amazon, and Starbucks. Building on his extensive experience and expertise, Anil Grandhi eventually founded AG FinTax, USA, further solidifying his reputation in the industry.
                        </div>
                    </div>
                </div>
            </div>
            {/* <Contact_us />
            <Our_branches /> */}
        </div>
    );
};

export default OurTeam;
