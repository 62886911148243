import React from "react";
import {  } from "react-router";
import { Row, Col, Button, Card } from "antd";
import "./OurTechnologies.scss";

import ourTech1 from "./../../assets/images/our-tech-1.png";
import angular from "./../../assets/images/angular.png";
/* import csharp from "./../../assets/images/technologies/csharp.png"; */
import android from "./../../assets/images/technologies/android.png";
import reactJs from "./../../assets/images/technologies/react-js.png";
import azure from "./../../assets/images/technologies/azure.png";
import htmL from "./../../assets/images/technologies/html.png";
import css3 from "./../../assets/images/technologies/css3.png";
import netDot from "./../../assets/images/technologies/net.png";
import python from "./../../assets/images/technologies/python.png";
import redux from "./../../assets/images/technologies/redux.png";
import sql from "./../../assets/images/technologies/sql.png";
import nodeJS from "./../../assets/images/technologies/node-js.png";
import Empowering from './Empowering/empowering';
import AdaptTechnology from './adaptTechnology/Adapt_tech';
import TechStack from './Our Tech Stack/tech_stack';
import NewsLetterCover from '../LandingPage/NewsLetterCover/business';
import Contact_us from '../LandingPage/Contact_us/Contact';
import Our_branches from '../LandingPage/Our_branches/branch';
import ctaImage from "../../assets/images/cta-img.png";


class OurTechnologies extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Empowering />
                <AdaptTechnology />
                <TechStack />
                {/* <NewsLetterCover /> */}
                {/* <Contact_us />
                <Our_branches /> */}
            </React.Fragment>
        );
    }
}
export default (OurTechnologies);

