// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import Module_image from '../GlobalModules/Image_hs';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Streamline Production with Accurate ",
    headingSpan: "Bill of Materials ",
    headingPart2: " Features",
    subtext: "Manage production with a detailed Bill of Materials system that tracks components, sub-assemblies, and raw materials for efficient manufacturing. "
};

const features = [
    { question: 'BOM Creation and Management', answer: 'Create and manage detailed Bills of Materials (BOMs) with information on parts, quantities, and specifications. This feature supports accurate production planning, ensures correct material usage, and facilitates efficient manufacturing processes. ' },
    { question: 'Version Control', answer: 'Track revisions and changes to BOMs to ensure production accuracy and maintain historical records. This feature helps manage updates, ensures consistency, and provides visibility into changes affecting manufacturing. ' },
    { question: 'Cost Estimation', answer: 'Calculate the cost of materials and components required for production. This feature supports better pricing strategies, budgeting, and financial planning by providing accurate cost estimates for manufacturing processes. ' },
    { question: 'Inventory Linking', answer: 'Integrate BOMs with inventory systems to manage the availability of raw materials and components. This feature ensures material availability, prevents shortages, and supports efficient inventory management. ' },
    { question: 'Production Planning Integration', answer: 'Align BOMs with production schedules and capacity planning to optimize manufacturing operations. This feature ensures that materials are available when needed, supports production efficiency, and enhances overall workflow. ' },
    { question: 'Supplier Management', answer: 'Manage preferred suppliers for each component and negotiate pricing to ensure cost-effective procurement. This feature supports supplier relationships, improves cost management, and ensures timely availability of materials. ' },
    { question: 'Waste and Scrap Management', answer: 'Track waste and scrap generated during production to minimize losses and improve resource utilization. This feature helps identify inefficiencies, reduce material waste, and enhance overall production efficiency. ' },
    { question: 'Compliance and Quality Checks', answer: 'Ensure that all materials meet quality and compliance standards before production. This feature supports adherence to industry regulations, improves product quality, and reduces the risk of non-compliance. ' },
    { question: 'Reporting and Analysis', answer: 'Generate reports on BOM costs, revisions, and usage patterns to gain insights into production efficiency and material costs. This feature supports data-driven decision-making and helps optimize manufacturing processes. ' },
];

const BuildUpMaterials = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                <Row className="ERP_alignmentModules" gutter={16}>
                        <Col
                            xs={{ span: 24, order: 2 }} // Span 24 and second on extra small screens
                            sm={{ span: 24, order: 2 }} // Span 24 and second on small screens
                            md={{ span: 24, order: 2 }} // Span 13 and first on medium screens
                            lg={{ span: 13, order: 1 }} // Span 13 and first on large screens
                            xl={{ span: 13, order: 1 }} // Span 13 and first on extra large screens
                        >
                            <Module_image />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }} // Span 24 and first on extra small screens
                            sm={{ span: 24, order: 1 }} // Span 24 and first on small screens
                            md={{ span: 24, order: 1 }} // Span 11 and second on medium screens
                            lg={{ span: 11, order: 2 }} // Span 11 and second on large screens
                            xl={{ span: 11, order: 2 }} // Span 11 and second on extra large screens
                        >
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}

        </React.Fragment>
    );
};

export default (BuildUpMaterials);
