// FinancialAccounting.js
import React, { useState } from 'react';
import { Row, Col } from 'antd';

import VideoPlayer from '../GlobalModules/VideoPlayer';
import Heading from '../GlobalModules/Heading';
import FeatureList from '../GlobalModules/FeatureList';
import NewsLetterCover from '../../Screens/LandingPage/NewsLetterCover/business';
import Contact_us from '../../Screens/LandingPage/Contact_us/Contact';
import Our_branches from '../../Screens/LandingPage/Our_branches/branch';
import Image11 from '../../assets/images/erpmodules/image 11.svg';
import Module_image from '../GlobalModules/Image_hs';


// Define the content and features data here or import them from a separate file if needed
const content = {
    headingPart1: "Drive End-to-End Efficiency with Integrated ",
    headingSpan: "Supply Chain Management",
    subtext: "Improve supply chain efficiency by integrating procurement, production, inventory, and logistics management to ensure smooth operations. "
};

const features = [
    { question: 'Demand Forecasting', answer: 'Predict demand with advanced data analytics to ensure optimal stock levels and meet market needs. This feature supports inventory planning, reduces stockouts, and improves supply chain responsiveness by forecasting demand accurately. ' },
    { question: 'Supplier Collaboration', answer: 'Engage in real-time collaboration with suppliers for better alignment on inventory levels and delivery schedules. This feature enhances communication, improves supply chain coordination, and strengthens supplier relationships. ' },
    { question: 'Inventory Optimization', answer: 'Balance stock levels across multiple locations to reduce holding costs and minimize excess inventory. This feature supports efficient inventory management, improves stock turnover, and enhances overall supply chain efficiency. ' },
    { question: 'Order Fulfillment Management', answer: ' Streamline order management and fulfillment processes to ensure timely delivery and customer satisfaction. This feature improves order accuracy, reduces lead times, and supports efficient supply chain operations. ' },
    { question: 'Transportation Management', answer: 'Plan and manage logistics for cost-effective and timely deliveries. This feature optimizes transportation routes, reduces shipping costs, and improves delivery performance by managing logistics effectively. ' },
    { question: 'Risk Management', answer: 'Identify and mitigate risks in the supply chain using predictive analytics and risk assessment tools. This feature supports proactive risk management, enhances supply chain resilience, and minimizes potential disruptions. ' },
    { question: 'Compliance Management', answer: 'Ensure adherence to industry regulations and standards throughout the supply chain. This feature supports regulatory compliance, reduces the risk of non-compliance, and enhances overall supply chain governance. ' },
    { question: 'Cost Control', answer: 'Track and manage costs at every stage of the supply chain to identify and reduce waste and inefficiencies. This feature improves cost management, supports budget control, and enhances overall supply chain profitability.' },
    { question: 'End-to-End Visibility', answer: 'Gain real-time visibility across the entire supply chain for better decision-making and operational control. This feature provides insights into supply chain performance, supports informed decisions, and improves overall efficiency. ' },
];

const SupplyChainManagement = (props) => {
    const [selectedFeature, setSelectedFeature] = useState(features[0]);

    const toggleFeature = (feature) => {
        setSelectedFeature(selectedFeature === feature ? null : feature);
    };

    return (
        <React.Fragment>
            <div className='module-top'>
                <div className='module-top-container'>
                <Row className="ERP_alignmentModules" gutter={16}>
                        <Col
                            xs={{ span: 24, order: 2 }} // Span 24 and second on extra small screens
                            sm={{ span: 24, order: 2 }} // Span 24 and second on small screens
                            md={{ span: 24, order: 2 }} // Span 13 and first on medium screens
                            lg={{ span: 13, order: 1 }} // Span 13 and first on large screens
                            xl={{ span: 13, order: 1 }} // Span 13 and first on extra large screens
                        >
                            <Module_image />
                        </Col>
                        <Col
                            xs={{ span: 24, order: 1 }} // Span 24 and first on extra small screens
                            sm={{ span: 24, order: 1 }} // Span 24 and first on small screens
                            md={{ span: 24, order: 1 }} // Span 11 and second on medium screens
                            lg={{ span: 11, order: 2 }} // Span 11 and second on large screens
                            xl={{ span: 11, order: 2 }} // Span 11 and second on extra large screens
                        >
                            <Heading
                                part1={content.headingPart1}
                                span={content.headingSpan}
                                part2={content.headingPart2}
                                subtext={content.subtext}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='module'>
                <div className='module-parent'>
                    <FeatureList
                        features={features}
                        selectedFeature={selectedFeature}
                        onSelectFeature={toggleFeature}
                    />
                </div>
            </div>

            {/* <NewsLetterCover /> */}
            {/* <Contact_us />
            <Our_branches /> */}
            
        </React.Fragment>
    );
};

export default (SupplyChainManagement);
